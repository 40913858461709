<div ref="htmlPopup">
  <ul v-for="(sectionCode, index) in sortedSectionCodes" :key="index">
    <span class="font-semibold">{{ sectionCode }}</span>:
    <li v-for="(feature, index) in featuresGroupedBySectionCode[sectionCode]" :key="index" class="pl-4">
      <span class="font-semibold">{{ this.typeOf(feature.featureId) }}</span>:
      <span v-if="this.inViewMode || this.inReadOnlyMode" class="underline cursor-pointer" @click="this.selectFeature(feature.geojson)">
        {{ feature.classificationName }}
      </span>
      <span v-if="this.inEditMode" class="underline cursor-pointer" @click="this.programmaticSelectFeature(feature.featureId)">
        {{ feature.classificationName }}
      </span>
      <DeleteFeatureButton :feature='this.featureDetails(feature)' />
    </li>
  </ul>
</div>
