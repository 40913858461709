import _ from 'lodash'
import { mapStores, mapState } from 'pinia'

import { useMapStore, useModeStore, useGeometryStore } from '../stores'
import { EditButton, MoveBuildingButton } from './buttons'

export default {
  name: 'ViewMode',
  computed: {
    ...mapStores(useMapStore, useModeStore, useGeometryStore),
    ...mapState(useModeStore, ['inReadOnlyMode']),
    buttonTooltip() { return this.inReadOnlyMode ? "Map is in read-only mode. Once the survey is complete refresh the page to enable editing" : ""}
  },
  methods: {
    enterEditMode() { this.modeStore.enterEditMode() },
    enterBulkMoveMode() { this.modeStore.enterBulkMoveMode() },
    selectFeatureCallback(e) {
      // Among all the features that are rendered at the clicked point, it was decided to prioritize the first one fetched
      const renderedFeature = this.mapStore.getRenderedFeatures(e.point)[0]
      if (renderedFeature) { renderedFeature.id = renderedFeature.properties.feature_id }
      this.geometryStore.setSelectedFeature(renderedFeature)
    }
  },
  async mounted() {
    if (this.modeStore.previous != 'init') { // No refresh after map initialization
      const refresh = await this.geometryStore.refreshFeatures(this.modeStore.initializeError)
      if (!refresh) { return }
    }

    _.keys(this.mapStore.layers).sort().forEach((name) => {
      this.mapStore.addViewSource(name)
      this.mapStore.addViewLayers(name)
    })

    this.mapStore.addMenuFolders({
      displayColorCallback: this.mapStore.setViewColor,
      lineWidthCallback: this.mapStore.setLineWidth,
      pointRadiusCallback: this.mapStore.setPointRadius
    })

    if (this.modeStore.previous == 'init') {
      this.mapStore.plugins.perimeterSegmentLabels.addControls()
      this.mapStore.plugins.perimeterSegmentAngles.addControls()
      this.mapStore.plugins.defectAngles.addControls()
      this.mapStore.plugins.classificationIcons.addControls()
      this.mapStore.plugins.conditionScoreBackgrounds.addControls()
      this.mapStore.loadSettings()
    } else {
      this.$parent.modeChanged()
    }

    this.mapStore.map.once('idle', () => this.modeStore.stopLoading())
    this.mapStore.map.on('click', this.selectFeatureCallback)
  },
  unmounted() {
    _.keys(this.mapStore.layers).forEach(name => {
      this.mapStore.removeViewLayers(name)
      this.mapStore.removeViewSource(name)
    })

    this.mapStore.map.off('click', this.selectFeatureCallback)
    this.geometryStore.setSelectedFeature(null)
    this.mapStore.$patch({ popupData: null })
  },
  components: { EditButton, MoveBuildingButton },
  template: `
  <div class='absolute left-2 bottom-8'>
    <EditButton :disabled='inReadOnlyMode' :handle-click='enterEditMode' :tooltipText="buttonTooltip"/>
    <MoveBuildingButton :disabled='inReadOnlyMode' :handle-click='enterBulkMoveMode' :tooltipText="buttonTooltip"/>
  </div>
  `
}
