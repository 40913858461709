import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['bulkEditButton', 'selectAllCheckbox', 'selectCheckbox']

  connect() {
    if (this.hasSelectCheckboxTarget) this.refreshBulkEditButton()
  }

  toggleSelectAll(event) {
    this.selectAllCheckboxTarget.indeterminate = false
    this.selectCheckboxTargets.forEach(checkbox =>  checkbox.checked = event.target.checked)
    this.refreshBulkEditButton()
  }

  refreshBulkEditButton() {
    const selectedRecords = this.selectCheckboxTargets.filter((checkbox) => checkbox.checked === true).length
    const noSelectedRecords = selectedRecords === 0

    this.selectAllCheckboxTarget.checked = !noSelectedRecords && this.selectCheckboxTargets.length === selectedRecords
    this.selectAllCheckboxTarget.indeterminate = selectedRecords > 0 && this.selectCheckboxTargets.length != selectedRecords
    this.bulkEditButtonTarget.disabled = noSelectedRecords
  }
}
