import * as Turf from '@turf/turf'
import ZLayers from '../../../../z_layers'
import { useMapStore, useModeStore } from '../stores'
export default class Grid {
  constructor() {
    this.config = {}
  }

  initialize() {
    const mapStore = useMapStore()
    const MAX_OFFSET = 1000
    const MIN_OFFSET = -1000
    const MAX_ANGLE = 180
    const MIN_ANGLE = -180

    const applyIfVisible = () => {
      if (mapStore.map.getLayer('grid') && mapStore.map.getSource('grid-lines')) {
        this.applyConfig()
      }
    }

    this.config = {
      Apply: this.applyConfig,
      Remove: this.remove,
      center: this.config.center || mapStore.locationCoords,
      visible: false,
      size: 50,
      unit: 'feet',
      scale: 1,
      lineWidth: 1,
      opacity: 1,
      rotation: 0,
      color: mapStore.colors.line.white,
      northSouthOffset: 0,
      eastWestOffest: 0,
      nudging: {
        nudgeNorth: () => { nsControl.setValue(Math.min(MAX_OFFSET, nsControl.getValue() + this.config.nudging.nudgeAmount)); applyIfVisible() },
        nudgeSouth: () => { nsControl.setValue(Math.max(MIN_OFFSET, nsControl.getValue() - this.config.nudging.nudgeAmount)); applyIfVisible() },
        nudgeEast: () => { ewControl.setValue(Math.min(MAX_OFFSET, ewControl.getValue() + this.config.nudging.nudgeAmount)); applyIfVisible() },
        nudgeWest: () => { ewControl.setValue(Math.max(MIN_OFFSET, ewControl.getValue() - this.config.nudging.nudgeAmount)); applyIfVisible() },
        nudgeAmount: 1,
        unit: 'feet',
        rotateClockise: () => { rtControl.setValue(Math.min(MAX_ANGLE, rtControl.getValue() + this.config.nudging.rotationAmount)); rtControl._callOnFinishChange(); applyIfVisible() },
        rotateCounterclockwise: () => { rtControl.setValue(Math.max(MIN_ANGLE, rtControl.getValue() - this.config.nudging.rotationAmount)); rtControl._callOnFinishChange(); applyIfVisible() },
        rotationAmount: 0.1
      }
    }

    const controls = mapStore.menu.addFolder('Grid')
    controls.close()
    controls.add(this.config, 'size', 1, 2000, 1)
    controls.add(this.config, 'lineWidth', 1, 10, 1).name('line width')
    controls.add(this.config, 'opacity', 0, 1)
    controls.add(this.config, 'scale', 1, 20, 1)
    const nsControl = controls.add(this.config, 'northSouthOffset', MIN_OFFSET, MAX_OFFSET, 1).name('north / south offset').onChange(() => mapStore.saveSettings())
    const ewControl = controls.add(this.config, 'eastWestOffest', MIN_OFFSET, MAX_OFFSET, 1).name('east / west offset').onChange(() => mapStore.saveSettings())
    const rtControl = controls.add(this.config, 'rotation', MIN_ANGLE, MAX_ANGLE, 0.1).onChange(() => mapStore.saveSettings())
    controls.add(this.config, 'unit', [ 'feet', 'meters' ]).disable()
    controls.add(this.config, 'color', mapStore.colors.line)
    controls.add(this.config, 'Apply').onChange(() => mapStore.saveSettings())
    controls.add(this.config, 'Remove').onChange(() => mapStore.saveSettings())

    const nudgeControls = mapStore.menu.addFolder('Grid Nudging')
    nudgeControls.close()
    nudgeControls.add(this.config.nudging, 'nudgeNorth').name('Nudge North')
    nudgeControls.add(this.config.nudging, 'nudgeSouth').name('Nudge South')
    nudgeControls.add(this.config.nudging, 'nudgeEast').name('Nudge East')
    nudgeControls.add(this.config.nudging, 'nudgeWest').name('Nudge West')
    nudgeControls.add(this.config.nudging, 'nudgeAmount', 1, 10, 1).name('nudge amount')
    nudgeControls.add(this.config.nudging, 'unit', [ 'feet', 'meters' ]).disable()
    nudgeControls.add(this.config.nudging, 'rotateClockise').name('Rotate Clockwise')
    nudgeControls.add(this.config.nudging, 'rotateCounterclockwise').name('Rotate Counter Clockwise')
    nudgeControls.add(this.config.nudging, 'rotationAmount', 0.1, 5, 0.1).name('rotation amount')

    mapStore.$patch({ grid: this })
  }

  applyConfig = () => {
    const mapStore = useMapStore()

    this.remove()

    mapStore.map.addSource('grid-lines', {
      'type': 'geojson',
      'data': Grid.geometry(
        this.config.center,
        this.config.unit,
        this.config.size,
        this.config.scale,
        this.config.rotation,
        this.config.northSouthOffset,
        this.config.eastWestOffest
      )
    })

    mapStore.map.addLayer({
      'id': 'grid',
      'type': 'line',
      'source': 'grid-lines',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': this.config.color,
        'line-width': this.config.lineWidth,
        'line-opacity': this.config.opacity
      }
    }, ZLayers.myPosition.call(mapStore, 'grid'))

    this.config.visible = true
  }

  remove = () => {
    const mapStore = useMapStore()
    if (mapStore.map.getLayer('grid')) { mapStore.map.removeLayer('grid') }
    if (mapStore.map.getSource('grid-lines')) { mapStore.map.removeSource('grid-lines') }
    this.config.visible = false
  }

  refreshControls() { controls.controllers.map((c) => c.updateDisplay()) }

  modeChanged() {
    const modeStore = useModeStore()
    if (modeStore.inBulkMoveMode || modeStore.inPlacementMode) {
      const mapStore = useMapStore()
      if (mapStore.map.getLayer('grid')) { mapStore.map.removeLayer('grid') }
      if (mapStore.map.getSource('grid-lines')) { mapStore.map.removeSource('grid-lines') }
    }
  }

  // unit: 'feet' or 'meters'
  // size: number of concentric boxes
  // scale: size of grid square
  // rotation: degrees to rotate the grid
  // northSouthOffset: offset (in provided unit) from center point
  // eastWestOffest: offset (in provided unit) from center point
  static geometry(
    centerCoordinates,
    unit,
    size,
    scale,
    rotation,
    northSouthOffset,
    eastWestOffest,
  ) {
    let coordinates = []

    let center = Turf.point(centerCoordinates)
    let options = { units: unit }

    let dir = {
      north: 0,
      east: 90,
      south: 180,
      west: 270
    }

    let gridLineLength = size * scale

    center = Turf.destination(center, northSouthOffset, dir.north, options)
    center = Turf.destination(center, eastWestOffest, dir.east, options)

    // center & east, (north / south)
    for (let i = 0; i <= size; i++) {
      let start = Turf.destination(center, i * scale, dir.east, options)
      start = Turf.destination(start, gridLineLength, dir.north, options)

      let end = Turf.destination(center, i * scale, dir.east, options)
      end = Turf.destination(end, gridLineLength, dir.south, options)

      coordinates.push([start.geometry.coordinates, end.geometry.coordinates])
    }

    // west (north / south)
    for (let i = 1; i <= size; i++) {
      let start = Turf.destination(center, i * scale, dir.west, options)
      start = Turf.destination(start, gridLineLength, dir.north, options)

      let end = Turf.destination(center, i * scale, dir.west, options)
      end = Turf.destination(end, gridLineLength, dir.south, options)

      coordinates.push([start.geometry.coordinates, end.geometry.coordinates])
    }

    // center & north (east / west)
    for (let i = 0; i <= size; i++) {
      let start = Turf.destination(center, i * scale, dir.north, options)
      start = Turf.destination(start, gridLineLength, dir.west, options)

      let end = Turf.destination(center, i * scale, dir.north, options)
      end = Turf.destination(end, gridLineLength, dir.east, options)

      coordinates.push([start.geometry.coordinates, end.geometry.coordinates])
    }

    // south (east / west)
    for (let i = 1; i <= size; i++) {
      let start = Turf.destination(center, i * scale, dir.south, options)
      start = Turf.destination(start, gridLineLength, dir.west, options)

      let end = Turf.destination(center, i * scale, dir.south, options)
      end = Turf.destination(end, gridLineLength, dir.east, options)

      coordinates.push([start.geometry.coordinates, end.geometry.coordinates])
    }

    let mls = Turf.multiLineString(coordinates)
    return Turf.transformRotate(mls, rotation).geometry
  }
}
