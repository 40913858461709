import * as Turf from '@turf/turf'
import _ from 'lodash'
import ZLayers from '../../../../z_layers'
import { useMapStore, useGeometryStore, useModeStore, useLegendStore } from '../stores/'
import { perimeterCenter } from '../stores/geometry_store/utils'

export default class SectionLabels {
  constructor() {
    this.sourceName = 'section-labels'
    this.mapStore = useMapStore()
    this.geometryStore = useGeometryStore()
    this.modeStore = useModeStore()
    this.legendStore = useLegendStore()
    this.layerFilters = ['==', 'visible', true]
  }

  initialize() {
    const isDeepLinking = this.mapStore.deepLinkedFeature
    const deepLinkingToMe = (sectionId) => sectionId == this.mapStore.deepLinkedFeature.section_id
    const isVisible = (sectionId) => isDeepLinking ? deepLinkingToMe(sectionId) : true
    const initialVisibility = _.mapValues(this.geometryStore.sections, (_, sectionId) => isVisible(sectionId))
    this.legendStore.sectionVisibility = initialVisibility

    this.mapStore.map.addSource(this.sourceName, {
      'type': 'geojson',
      'data': Turf.featureCollection(this.geometryStore.sectionCenters.filter(s => initialVisibility[s.id]))
    })

    this.mapStore.map.addLayer({
      'id': this.sourceName,
      'type': 'symbol',
      'source': this.sourceName,
      'layout': {
        'text-field': ['get', 'section_code'],
        'text-allow-overlap': true
      },
      'paint': {
          'text-color': this.mapStore.colors.line.green,
          'text-halo-color': this.mapStore.colors.line.black,
          'text-halo-width': 1
      },
      'filter': this.layerFilters,
    }, ZLayers.myPosition.call(this.mapStore, this.sourceName))
  }

  modeChanged() {
    if (this.modeStore.inPlacementMode) {
      // Show all section labels in placement mode by ignoring the visible feature property
      this.mapStore.map.setFilter(this.sourceName, null)
      return this.mapStore.map.setLayoutProperty(this.sourceName, 'visibility', 'visible')
    }

    if (this.modeStore.previous === 'placement') {
      // Re-enable the visible filter when exiting placement mode, so section labels are shown/hidden based on the
      // legend state
      this.mapStore.map.setFilter(this.sourceName, this.layerFilters)
    }

    if (this.modeStore.inBulkMoveMode) {
      return this.mapStore.map.setLayoutProperty(this.sourceName, 'visibility', 'none')
    }

    this.mapStore.setViewSource(this.sourceName, this.geometryStore.sectionCenterCollection)
    this.mapStore.map.setLayoutProperty(this.sourceName, 'visibility', 'visible')
  }

  static refreshVisibility() {
    const geometryStore = useGeometryStore()
    _.forEach(geometryStore.sectionCenters, ({ id }) => {
      geometryStore.setSectionCenterVisibility(id, useLegendStore().sectionVisibility[id])
    })

    useMapStore().setViewSource('section-labels', geometryStore.sectionCenterCollection)
  }

  static repositionCenter(sectionId) {
    const geometryStore = useGeometryStore()
    const mapStore = useMapStore()
    const section = geometryStore.sections[sectionId]
    const center = perimeterCenter(section.updatedVertices, section.id, section.code)
    section.center = center
    mapStore.setViewSource('section-labels', geometryStore.sectionCenterCollection)
  }
}
