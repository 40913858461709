import _ from 'lodash'
import { defineStore } from 'pinia'

export const useModeStore = defineStore('mode', {
  state: () => ({
    modes: ['readOnly', 'view', 'edit', 'bulkMove', 'placement'],
    initial: 'view', // switched to readOnly if survey is in progress
    current: 'init',
    previous: null,
    loading: false,
    leftPanel: true,
    bulkMove: {
      sectionId: null
    },
    placement: {
      sectionId: null,
      sectionCode: null,
      perimeters: [],
      closed: true,
      target: null
    },
    errorMessage: undefined, // TODO: Update to use error codes when validations are improved
    errors: {
      initializing: false,
      enterEditMode: false,
      saving: false,
      loadingForm: false,
      deletingFeature: false
    },
  }),
  getters: {
    inReadOnlyMode: (state) => state.current === 'readOnly',
    inViewMode: (state) => state.current === 'view',
    inEditMode: (state) => state.current === 'edit',
    inBulkMoveMode: (state) => state.current === 'bulkMove',
    inPlacementMode: (state) => state.current === 'placement',
    showLoader: (state) => state.loading || _.some(_.values(state.errors)),
    showLeftPanel: (state) => state.leftPanel,
  },
  actions: {
    startLoading() { this.loading = true },
    stopLoading() { this.loading = false },
    startLeftPanel() { this.leftPanel = true },
    stopLeftPanel() { this.leftPanel = false },
    initializeError() {
      this.stopLoading()
      this.errors.initializing = true
    },
    enteringEditError(error) {
      this.stopLoading()
      this.errors.enterEditMode = true
      this.errorMessage = error
    },
    savingError(error) {
      this.stopLoading()
      this.errors.saving = true
      this.errorMessage = error
    },
    loadingFormError(error) {
      this.stopLoading()
      this.errors.loadingForm = true
      this.errorMessage = error
    },
    deletingFeatureError(error) {
      this.stopLoading()
      this.errors.deletingFeature = true
      this.errorMessage = error
    },
    enterMode(mode) {
      if (_.indexOf(this.modes, mode) === -1) {
        console.error(`Invalid mode: ${mode}`)
        return false
      }

      if (this.current === 'readOnly') {
        console.error('Cannot change modes while in Read Only mode')
        return false
      }

      this.previous = this.current
      this.current = mode
    },
    clearError(name) {
      if (_.indexOf(_.keys(this.errors), name) === -1) {
        console.error(`Invalid error name: ${name}`)
        return false
      }
      this.errors[name] = false
      this.errorMessage = undefined
    },
    enterPreviousMode() {
      const mode = this.previous ? this.previous : this.initial
      this.enterMode(mode)
    },
    enterViewMode() {
      this.enterMode('view')
    },
    enterEditMode() {
      this.enterMode('edit')
    },
    enterBulkMoveMode(id = null) {
      this.bulkMove.sectionId = id
      this.enterMode('bulkMove')
    },
    enterPlacementMode(sectionId = null, sectionCode = null, perimeters = [], closed = true, target) {
      this.placement.sectionId = sectionId
      this.placement.sectionCode = sectionCode
      this.placement.perimeters = perimeters
      this.placement.closed = closed
      this.placement.target = target
      this.enterMode('placement')
    }
  }
})
