import { mapStores } from 'pinia'
import _ from 'lodash'

import { useMapStore, useGeometryStore, useModeStore, useLockStore } from '../../../../stores'

export default {
  name: 'ForceClosePolygonLink',
  props: {
    item: Object
  },
  computed: {
    ...mapStores(useMapStore, useGeometryStore, useModeStore, useLockStore),
    isSection() { return this.item.type === 'section' },
    isInteriorPerimeterGroup() { return this.item.type === 'interior-perimeter-group' },
    isForceCloseLinkEnabled() {
      // Determines if the "Force Close" link should be enabled or disabled.
      // Note that the link can be enabled even if the section or interior perimeter group is not eligible
      // for force closing due to invalid geometries. This is done intentionally to inform users clearly with
      // an alert message when they click.
      if (!this.modeStore.inViewMode) { return false }
      if (this.isSection) { return this.geometryStore.sections[this.item.id]?.isUnclosed }
      if (this.isInteriorPerimeterGroup) {
        const sectionId = this.item.properties.sectionId
        const groupId = this.item.properties.groupId
        return this.geometryStore.sections[sectionId]?.isGroupUnclosed(groupId)
      }
    },
    isForceClosable() {
      // Determines if the section or interior perimeter group is eligible for force closing.
      // Note that the section or interior perimeter group may not be eligible for force closing even when the link is enabled.
      // This is done intentionally to inform users clearly with an alert message when they click.
      if (!this.isForceCloseLinkEnabled) { return false }
      if (this.isSection) {
        const perimeters = this.geometryStore.sections[this.item.id].perimeters
        return perimeters.length > 1 || perimeters[0].geometry.coordinates.length > 2
       }
      if (this.isInteriorPerimeterGroup) {
        const sectionId = this.item.properties.sectionId
        const groupId = this.item.properties.groupId
        const perimeters = this.geometryStore.sections[sectionId].interiorPerimeters.filter((p) => p.properties.group_id === groupId)
        return perimeters.length > 1 || perimeters[0].geometry.coordinates.length > 2
      }
    }
  },
  methods: {
    async closePolygon() {
      if (!this.isForceClosable) {
        alert(
          `The ${this.isSection ? 'section' : 'perimeter interior group'} cannot be force closed because it has fewer than 3 points. ` +
          `You can add more points in edit mode to make it eligible for force closing.`
        )
        return
      }

      const confirmed = confirm('Are you sure?')
      if(!confirmed) { return }

      this.modeStore.startLoading()

      const lockConfirmed = await this.lockStore.confirmClaim()
      if (!lockConfirmed) {
        if (lockConfirmed !== null) { this.modeStore.stopLoading() } // !null is a confirm cancel
        return false
      }

      const locked = await this.lockStore.tryLock(this.lockStore.claimLock, this.modeStore.savingError)
      if (!locked) { return }

      let perimeters
      if (this.isSection) {
        perimeters = this.geometryStore.sections[this.item.id].perimeters
      }
      if (this.isInteriorPerimeterGroup) {
        const sectionId = this.item.properties.sectionId
        const groupId = this.item.properties.groupId
        perimeters = this.geometryStore.sections[sectionId].interiorPerimeters.filter((p) => p.properties.group_id === groupId)
      }

      const saved = await this.geometryStore.closeAndSavePolygon(perimeters)
      if (!saved) { return }

      const refreshed = await this.refresh()
      if (!refreshed) { return }

      const released = await this.lockStore.tryLock(this.lockStore.releaseLock, this.modeStore.savingError)
      if (!released) { return }

      this.modeStore.stopLoading()
    },
    async refresh() {
      const result = await this.geometryStore.refreshFeatures(this.modeStore.initializeError)
      if (!result) { return false }

      _.keys(this.mapStore.layers).forEach(name => {
        this.mapStore.removeViewLayers(name)
        this.mapStore.removeViewSource(name)
      })

      _.keys(this.mapStore.layers).sort().forEach((name) => {
        this.mapStore.addViewSource(name)
        this.mapStore.addViewLayers(name)
      })

      this.mapStore.plugins.featureSelection.clear()
      this.mapStore.plugins.conditionScoreBackgrounds.refresh()
      this.mapStore.plugins.perimeterSegmentLabels.refresh()
      this.mapStore.plugins.perimeterSegmentAngles.refresh()
      this.mapStore.plugins.classificationIcons.refresh()

      return true
    }
  },
  template: `
    <li v-if="isSection || isInteriorPerimeterGroup">
      <a
        href='#'
        :class="{
          'pointer-events-none text-gray-200': !isForceCloseLinkEnabled,
          'hover:underline': isForceCloseLinkEnabled
        }"
        @click="closePolygon">Force Close
      </a>
    </li>
  `
}
