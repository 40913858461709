import * as Vue from 'vue/dist/vue.esm-bundler.js'
import { mapWritableState, mapActions } from 'pinia'
import { mapStores } from 'pinia'
import { ArrowTopRightOnSquareIcon, XMarkIcon } from '@heroicons/vue/20/solid'

import { useMapStore } from '../../../../stores/'
import MoveSectionLink from './move_section_link'
import DeleteLinks from './delete_links'
import RecenterGridLink from './recenter_grid_link'
import ForceClosePolygonLink from './force_close_polygon_link'
import AddPerimeterLinks from './add_perimeter_links'
import SplitPerimeterSegmentLink from './split_perimeter_segment_link'
import template from './index.html'

export default {
  name: 'ContextMenu',
  components: {
    ArrowTopRightOnSquareIcon,
    AddPerimeterLinks,
    SplitPerimeterSegmentLink,
    MoveSectionLink,
    RecenterGridLink,
    DeleteLinks,
    ForceClosePolygonLink,
    XMarkIcon
  },
  props: {
    item: Object,
    index: Number
  },
  computed: {
    ...mapStores(useMapStore),
    shouldUpdatePositionTop() {
      return (this.item.type === 'location' || this.item.type === 'section') && (this.index === 0 || this.index === 1);
    },
  },
  methods: {
    close() { this.mapStore.$patch({ leftPanelSubMenuComponent: null, leftPanelSubMenuProps: null }) },
    handleClickOutside(event) {
      const isClickOutside = !this.$refs['contextMenu_' + this.index].contains(event.target)
      if (isClickOutside) { this.close() }
    }
  },
  mounted() {
    if (this.shouldUpdatePositionTop) this.positionTop = 'top-5'
  },
  beforeMount() { document.addEventListener('click', this.handleClickOutside) },
  unmounted() { document.removeEventListener('click', this.handleClickOutside) },
  template: template
}
