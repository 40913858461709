const domainTypes = {
  DEFECT: 'Defect',
  INVENTORY: 'Inventory',
  PERIMETER_SEGMENT: 'PerimeterSegment',
  SECTION: 'Section'
}

const targets = {
  SECTION: 'Section',
  PERIMETER: 'Perimeter',
  INTERIOR_PERIMETER: 'Interior Perimeter',
  SECTION_ASSEMBLY: 'Section Assembly',
  PERIMETER_ASSEMBLY: 'Perimeter Assembly',
  INVENTORY: 'Inventory',
  DEFECT: 'Defect',
  PCI_SAMPLE_UNIT: 'PCI Sample Unit',
  PCI_INVENTORY: 'PCI Inventory'
}

const connectionStatuses = {
  CONNECTED: 'connected',
  OUT_OF_ORDER: 'out_of_order',
  DISCONNECTED: 'disconnected',
  MISSING_GEOMETRIES: 'missing_geometries',
  OPEN_GROUP: 'open_group'
}

const events = {
  selectionChange: 'custom.selectionchange',
  styleChange: 'custom.stylechange'
}

export const constants = {
  domainTypes,
  targets,
  connectionStatuses,
  events
}

