import _ from 'lodash'
import { defineStore } from 'pinia'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

import { DirectSelectPatch, SimpleSelectPatch, DrawMoveVector, EditMoveVector } from '../../draw_modes'
import theme from '../../theme'
import * as Getters from './getters'
import * as Actions from './actions'

export const useMapStore = defineStore('map', {
  state: () => ({
    provider: null,
    location: null,
    survey: null,
    map: null,
    menu: null,
    draw: null,
    grid: null,
    updatingSettings: false,
    leftPanelTitle: '',
    rightPanelTitle: '',
    leftPanelComponent: null,
    rightPanelComponent: null,
    leftPanelSubMenuComponent: null,
    leftPanelSubMenuProps: null,
    modalComponent: null,
    modalComponentProps: null,
    showModal: false,
    popupData: null,
    popupInstance: null,
    deepLinkedFeature: null,
    plugins: null,
    mapboxConfig: {
      accessToken: null,
      container: 'map',
      style: 'mapbox://styles/mapbox/satellite-streets-v12',
      zoom: 18
    },
    mapboxDrawConfig: {
      displayControlsDefault: false,
      controls: { trash: true },
      userProperties: true,
      boxSelect: false,
      styles: theme,
      modes: {
        ...MapboxDraw.modes,
        direct_select: DirectSelectPatch,
        simple_select: SimpleSelectPatch,
        move_vector: DrawMoveVector,
        edit_vector: EditMoveVector
      }
    },
    colors: {
      line: {
        green: '#39FF14',
        yellow: '#FFFF00',
        blue: '#00BFFF',
        orange: '#FFA500',
        red: '#FF0055',
        white: '#FFFFFF ',
        black: '#000000'
      }
    },
    layers: {
      perimeterSegments: {
        types: ['line'],
        color: 'blue',
        lineWidth: 3,
        pointRadius: 5,
        vertexRadius: 5,
        visible: true
      },
      inventories: {
        types: ['line', 'circle', 'fill'],
        color: 'yellow',
        lineWidth: 3,
        pointRadius: 5,
        vertexRadius: 5,
        visible: true
      },
      defects: {
        types: ['line', 'circle', 'fill'],
        color: 'red',
        lineWidth: 3,
        pointRadius: 5,
        vertexRadius: 5,
        visible: true
      }
    },
    layerFilterRule: {
      line: ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
      circle: ['==', '$type', 'Point'],
      fill: ['==', '$type', 'Polygon']
    },
    layerStyles: {
      line: { 'line-color': null },
      circle: { 'circle-color': null },
      fill: { 'fill-outline-color': null }
    },
    LAYER_POSITIONS: [
      'satellite',
      'custom-satellite',
      'pois',
      'condition-score-backgrounds',
      'grid',
      'section-labels',
      'view-layers',
      'view-mode-selected-geometry-bottom',
      'view-mode-selected-geometry-middle',
      'view-mode-selected-geometry-top',
      'draw-layers',
      'perimeter-segment-labels',
      'perimeter-segment-angles',
      'defect-angles',
      'classification-icons'
    ]
  }),
  getters: {
    locationCoords: Getters.locationCoords,
    layerNames: Getters.layerNames,
    layerPaint: Getters.layerPaint,
    colorProps: Getters.colorProps,
    colorName: Getters.colorName,
    layerConfigs: Getters.layerConfigs,
    getRenderedFeatures: Getters.getRenderedFeatures,
    showPopup: Getters.showPopup,
    isDeepLinkingToFeature: Getters.isDeepLinkingToFeature
  },
  actions: {
    initMap: Actions.initMap,
    switchMapProvider: Actions.switchMapProvider,
    fitMapToGeometries: Actions.fitMapToGeometries,
    hideMenu: Actions.hideMenu,
    showMenu: Actions.showMenu,
    addViewSource: Actions.addViewSource,
    addViewLayers: Actions.addViewLayers,
    removeViewSource: Actions.removeViewSource,
    removeViewLayers: Actions.removeViewLayers,
    removeSourceFeature: Actions.removeSourceFeature,
    setViewSource: Actions.setViewSource,
    showLayer: Actions.showLayer,
    hideLayer: Actions.hideLayer,
    setViewColor: Actions.setViewColor,
    setLineWidth: Actions.setLineWidth,
    setPointRadius: Actions.setPointRadius,
    addMenuFolder: Actions.addMenuFolder,
    addMenuFolderCallback: Actions.addMenuFolderCallback,
    addMenuFolders: Actions.addMenuFolders,
    addDrawFeatures: Actions.addDrawFeatures,
    setDrawFeaturesProperty: Actions.setDrawFeaturesProperty,
    addDrawListener: Actions.addDrawListener,
    removeDrawListener: Actions.removeDrawListener,
    repositionDrawLayers: Actions.repositionDrawLayers,
    repositionPOILayers: Actions.repositionPOILayers,
    applyGridConfig: Actions.applyGridConfig,
    recenterGrid: Actions.recenterGrid,
    saveSettings: Actions.saveSettings,
    loadSettings: Actions.loadSettings,
    resetSettings: Actions.resetSettings,
    rightClickCallback: Actions.rightClickCallback,
    centerOnFeature: Actions.centerOnFeature,
    closeModal: Actions.closeModal,
  }
})
