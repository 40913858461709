import * as Vue from 'vue/dist/vue.esm-bundler.js'
import _ from 'lodash'
import * as Turf from '@turf/turf'
import { useMapStore, useGeometryStore, useModeStore } from '../../../../../stores'
import { mapStores, mapState } from 'pinia'
import { Api } from '../../../../../api'
import { ArrowLongUpIcon } from '@heroicons/vue/20/solid'
import template from './index.html'

export default {
  name: 'SplitPerimeterSegmentForm',
  props: {
    geometryId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      segmentOptions: [],
      classificationOptions: [],
      selectedSegment: null,
      selectedClassification: null
    }
  },
  computed: {
    ...mapStores(useMapStore, useGeometryStore, useModeStore),
    ...mapState(useModeStore, ['showLoader']),
    perimeterSegmentId() {
      return this.geometryStore.perimeterSegments[this.geometryId].model_id
    },
    perimeterSegmentCoords() {
      return this.geometryStore.perimeterSegments[this.geometryId].data.geometry.coordinates
    },
    isSubmitDisabled() {
      return this.selectedSegment === null || this.selectedClassification === null
    }
  },
  methods: {
    calculateSegmentOptions() {
      const firstSegment = _.take(this.perimeterSegmentCoords, 2)
      const lastSegment = _.takeRight(this.perimeterSegmentCoords, 2)
      const isFirstSegmentWesternmost = this.isFirstSegmentWesternmost(firstSegment, lastSegment)

      const options = [
        {
          bearing: Turf.bearing(firstSegment[0], firstSegment[1]),
          isFirstSegment: true,
          isWesternmost: isFirstSegmentWesternmost
        },
        {
          bearing: Turf.bearing(lastSegment[1], lastSegment[0]),
          isFirstSegment:false,
          isWesternmost: !isFirstSegmentWesternmost
        }
      ]

      // We want the westernmost segment to be the option at the left
      this.segmentOptions = options.sort((a, b) => b.isWesternmost - a.isWesternmost)
    },
    isFirstSegmentWesternmost(first, last) {
      // Find the longitude of the westernmost point of each segment
      let westernmostFirst = Math.min(first[0][0], first[1][0])
      let westernmostLast = Math.min(last[0][0], last[1][0])

      if (westernmostFirst === westernmostLast) {
        // If the westernmost points are the same, compare the other points
        westernmostFirst = Math.max(first[0][0], first[1][0])
        westernmostLast = Math.max(last[0][0], last[1][0])
      }

      // The lower the longitude, the more western the segment
      return westernmostFirst <= westernmostLast
    },
    selectSegmentOption(option) {
      this.selectedSegment = option
    },
    closeModal() {
      this.mapStore.closeModal()
    },
    async handleSubmit() {
      this.modeStore.startLoading()

      const splitFromStart = this.segmentOptions[this.selectedSegment].isFirstSegment
      const firstSegment = _.take(this.perimeterSegmentCoords, 2)
      const lastSegment = _.takeRight(this.perimeterSegmentCoords, 2)

      const splitAt = splitFromStart
        ? Turf.midpoint(firstSegment[0], firstSegment[1]).geometry.coordinates
        : Turf.midpoint(lastSegment[0], lastSegment[1]).geometry.coordinates

      const response = await Api.PerimeterSegments.split({
        perimeterSegmentId: this.perimeterSegmentId,
        classificationId: this.selectedClassification.id,
        splitAt,
        splitFromStart
      })

      if (response.errored()) { this.modeStore.savingError() }

      const refreshed = await this.refreshView()
      if (!refreshed) { return initializeError() }

      this.modeStore.stopLoading()
      this.closeModal()
    },
    async refreshView() {
      const result = await this.geometryStore.refreshFeatures(this.modeStore.initializeError)
      if (!result) { return false }

      _.keys(this.mapStore.layers).forEach(name => {
        this.mapStore.removeViewLayers(name)
        this.mapStore.removeViewSource(name)
      })

      _.keys(this.mapStore.layers).sort().forEach((name) => {
        this.mapStore.addViewSource(name)
        this.mapStore.addViewLayers(name)
      })

      this.mapStore.plugins.conditionScoreBackgrounds.refresh()
      this.mapStore.plugins.perimeterSegmentLabels.refresh()
      this.mapStore.plugins.perimeterSegmentAngles.refresh()
      this.mapStore.plugins.classificationIcons.refresh()

      return true
    }
  },
  async beforeMount() {
    this.modeStore.startLoading()

    this.calculateSegmentOptions()
    const response = await Api.PerimeterSegments.changeTypeOptions(this.perimeterSegmentId)
    if (response.errored()) {
      this.modeStore.loadingFormError()
      this.closeModal()
    } else {
      this.classificationOptions = response.data.classification_options
    }
    this.modeStore.stopLoading()
  },
  components: { ArrowLongUpIcon },
  template: template
}
