<div class="flex flex-col items-center justify-center absolute h-full w-full bg-opacity-70 z-40 bg-gray-900">
  <div class="bg-white shadow-md p-4 rounded-lg flex flex-col h-96 w-96">
    <span class="font-semibold">Split Segment</span>
    <span class="text-sm text-gray-900">Choose the desired side for the new segment</span>
    <div class="flex flex-col justify-between h-full">
      <div class="flex items-center justify-center h-full">
        <div
          id="option_0"
          class="h-32 w-32 border-solid border-2 rounded-lg cursor-pointer"
          :class="selectedSegment == 0 ? 'border-gray-900' : 'border-gray-200'"
          @click="selectSegmentOption(0)"
        >
          <ArrowLongUpIcon
            class="h-full"
            :class="selectedSegment == 0 ? 'fill-gray-900' : 'fill-gray-200'"
            :style="{ transform: `rotate(${segmentOptions[0].bearing}deg)` }"
          >
          </ArrowLongUpIcon>
        </div>
        <div
          id="option_1"
          class="h-32 w-32 border-solid border-2 rounded-lg ml-5 cursor-pointer"
          :class="selectedSegment == 1 ? 'border-gray-900' : 'border-gray-200'"
          @click="selectSegmentOption(1)"
        >
          <ArrowLongUpIcon
            class="h-full"
            :class="selectedSegment == 1 ? 'fill-gray-900' : 'fill-gray-200'"
            :style="{ transform: `rotate(${segmentOptions[1].bearing}deg)` }"
          >
          </ArrowLongUpIcon>
        </div>
      </div>

      <label for="classification" class="block text-sm font-medium text-gray-dark">Classification</label>
      <div class="flex items-center mt-2 space-x-2 w-full">
        <select v-model="selectedClassification" id="classificationSelect" class="w-full border-gray-light focus:border-blue focus:ring-blue placeholder-gray-light appearance-none block border focus:outline-none px-3 py-2 rounded-md shadow-sm text-sm">
          <option v-for="classification in classificationOptions" :key="classification.id" :value="classification">
            {{ classification.name }}
          </option>
        </select>
      </div>

      <div class="mt-4 flex justify-end">
        <button @click="closeModal" class="white-button">Cancel</button>
        <button @click="handleSubmit" :disabled="isSubmitDisabled" class="ml-3 blue-button">Save</button>
      </div>
    </div>
  </div>
</div>

