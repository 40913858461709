<div :ref="'contextMenu_' + index" data-test="contextMenu" class="absolute bg-white rounded-lg p-2 mb-1 h-auto top-0 -right-36 w-36">
  <ul class="text-xs font-semibold">
    <li class="h-6"><XMarkIcon @click="close" class="cursor-pointer h-4 w-4 ml-auto mr-0 text-gray-500"/></li>
    <hr class="mb-1" />
    <li v-for="menu in item.contextMenu" :key="menu.name" class="flex justify-between items-center hover:underline">
      <a :href="menu.linkTo" target="_blank">{{menu.name}}</a>
      <a :href="menu.linkTo" target="_blank"><ArrowTopRightOnSquareIcon class="h-4 w-4 text-gray-500"/></a>
    </li>
    <SplitPerimeterSegmentLink :item="item" />
    <MoveSectionLink :item="item" />
    <AddPerimeterLinks :item="item" />
    <RecenterGridLink :item="item" />
    <DeleteLinks :item="item" />
    <ForceClosePolygonLink :item="item" />
  </ul>
</div>
