import _ from 'lodash'
import { mapStores } from 'pinia'
import { Api } from '../../../../api'

import { useModeStore, useGeometryStore, useMapStore } from '../../../../stores'
import { constants } from '../../../../constants'

export default {
  name: 'DeleteLinks',
  props: {
    item: Object
  },
  computed: {
    ...mapStores(useModeStore, useGeometryStore, useMapStore),
    geometryOnlyMessage() { return `Do you want to delete only the geometry data for this item: "${this.item.label}"?` },
    geometryWithParentMessage() { return `Do you want to delete this item: "${this.item.label}", and its associated geometry?` },
    allGeometriesMessage() { return `Do you want to delete all the geometries under "${this.item.label}"?` },
    allGeometriesWithParentsMessage() { return `Do you want to delete all the items under "${this.item.label}" and their associated geometries?` },
    hasItems(){ return this.item.items.length > 0 },
    hasItemsWithGeometry(){
      if (this.isTopLevelPerimeterNode) {
        const section = this.geometryStore.sections[this.item.parentId]
        if (!section) { return false }
        if (this.item.type == 'perimeter-segments') { return section.perimeterIds.length > 0 }
        if (this.item.type == 'interior-perimeter-segments') { return section.interiorPerimeterIds.length > 0 }
      }
      if (this.isInteriorPerimeterGroupNode) {
        const section = this.geometryStore.sections[this.item.properties.sectionId]
        if (!section) { return false }
        return section.interiorPerimeters.filter(p => p.properties.group_id === this.item.properties.groupId).length > 0
      }
    },
    hasNoItems() { return !this.hasItems },
    isTopLevelPerimeterNode() { return ['perimeter-segments', 'interior-perimeter-segments'].includes(this.item.type) },
    isGeometryNode() { return this.item.type === 'geometry' },
    isPerimeterNode() { return this.isGeometryNode && this.item.geometryType === 'perimeter' },
    isInteriorPerimeterNode() { return this.isGeometryNode && this.item.geometryType === 'interiorPerimeter' },
    isInventoryOrDefectNode() { return ['inventories', 'defects'].includes(this.item.geometryType) },
    isInteriorPerimeterGroupNode() { return this.item.type === 'interior-perimeter-group' },
    canClosePolygon() {
      if (this.isPerimeterNode) {
        const perimeters = this.geometryStore.sections[this.item.properties.sectionId].perimeters
        const remainingPerimeters = perimeters.filter(p => p.id !== this.item.id)
        const canClose = !(remainingPerimeters.length === 1 && remainingPerimeters[0].geometry.coordinates.length < 3)
        return canClose
      }

      if (this.isInteriorPerimeterNode) {
        const closedGroup = this.geometryStore.perimeterSegments[this.item.id].closed
        if (!closedGroup) { return true }

        const sectionId = this.item.properties.sectionId
        const groupId = this.item.properties.groupId
        const perimeters = this.geometryStore.sections[sectionId].interiorPerimeters
        const groupPerimeters = perimeters.filter(p => p.properties.group_id === groupId)
        const remainingPerimeters = groupPerimeters.filter(p => p.id !== this.item.id)
        const canClose = !(remainingPerimeters.length === 1 && remainingPerimeters[0].geometry.coordinates.length < 3)
        return canClose
      }
     },
    canDeleteGeometry(){
      const hasGeometry = _.has(this.geometryStore[this.item.geometryType], this.item.id)
      return this.modeStore.inViewMode && this.hasNoItems && this.isInventoryOrDefectNode && hasGeometry
    },
    canDeleteItem(){
      if (!this.modeStore.inViewMode || this.hasItems || !this.isGeometryNode) { return false }
      if (this.isInventoryOrDefectNode) { return true }
      return this.canClosePolygon
    },
    canDeleteGroupGeometries(){
      return this.modeStore.inViewMode && this.isInteriorPerimeterGroupNode && this.item.properties.groupId && this.hasItemsWithGeometry
    },
    canDeleteGroupItems(){
      return this.modeStore.inViewMode && this.isInteriorPerimeterGroupNode && this.item.properties.groupId
    },
    canDeleteAllGeometries(){
      return this.modeStore.inViewMode && this.isTopLevelPerimeterNode && this.hasItemsWithGeometry
    },
    canDeleteAllItems(){
      return this.modeStore.inViewMode && this.isTopLevelPerimeterNode && this.hasItems
    }
  },
  methods: {
    classes(disabled){
      return {
        'hover:underline': disabled,
        'pointer-events-none text-gray-200': !disabled
      }
    },
    async refreshView() {
      this.mapStore.plugins.featureSelection.clear()

      const result = await this.geometryStore.refreshFeatures(this.modeStore.initializeError)
      if (!result) { return false }

      _.keys(this.mapStore.layers).forEach(name => {
        this.mapStore.removeViewLayers(name)
        this.mapStore.removeViewSource(name)
      })

      _.keys(this.mapStore.layers).sort().forEach((name) => {
        this.mapStore.addViewSource(name)
        this.mapStore.addViewLayers(name)
      })

      this.mapStore.plugins.conditionScoreBackgrounds.refresh()
      this.mapStore.plugins.perimeterSegmentLabels.refresh()
      this.mapStore.plugins.perimeterSegmentAngles.refresh()
      this.mapStore.plugins.classificationIcons.refresh()

      return true
    },
    async deleteGeometry(){
      let confirmed = confirm(this.allGeometriesMessage)
      if (!confirmed) { return }
      this.$parent.close()

      const id = this.item.id
      const deleteFunction = Api.Geometries.destroy
      const postDeleteCallback = this.refreshView
      await this.geometryStore.deleteWithLocking({ id, deleteFunction, postDeleteCallback })
    },
    async deleteItem(){

      let confirmed = confirm(this.geometryWithParentMessage)
      if (!confirmed) { return }
      this.$parent.close()

      const id = this.item.recordId
      const apiNamespace = _.upperFirst(_.camelCase(this.item.layer))
      const deleteFunction = Api[apiNamespace].destroy
      const postDeleteCallback = this.refreshView
      await this.geometryStore.deleteWithLocking({ id, deleteFunction, postDeleteCallback })
    },
    async deleteGroupGeometries() {
      let confirmed = confirm(this.allGeometriesMessage)
      if (!confirmed) { return }
      this.$parent.close()

      const sectionId = this.item.properties.sectionId
      const target = constants.targets.INTERIOR_PERIMETER
      const groupId = this.item.properties.groupId
      const deleteFunction = Api.Geometries.destroyAll
      const postDeleteCallback = this.refreshView
      await this.geometryStore.deleteWithLocking({ sectionId, target, groupId, deleteFunction, postDeleteCallback })
    },
    async deleteGroupItems() {
      let confirmed = confirm(this.allGeometriesMessage)
      if (!confirmed) { return }
      this.$parent.close()

      const sectionId = this.item.properties.sectionId
      const target = constants.targets.INTERIOR_PERIMETER
      const groupId = this.item.properties.groupId
      const deleteFunction = Api.PerimeterSegments.destroyAll
      const postDeleteCallback = this.refreshView
      await this.geometryStore.deleteWithLocking({ sectionId, target, groupId, deleteFunction, postDeleteCallback })
    },
    async deleteAllGeometries() {
      let confirmed = confirm(this.allGeometriesMessage)
      if (!confirmed) { return }
      this.$parent.close()

      const sectionId = this.item.parentId
      const target = this.item.type == 'perimeter-segments' ? constants.targets.PERIMETER : constants.targets.INTERIOR_PERIMETER
      const deleteFunction = Api.Geometries.destroyAll
      const postDeleteCallback = this.refreshView
      await this.geometryStore.deleteWithLocking({ sectionId, target, deleteFunction, postDeleteCallback })
    },
    async deleteAllItems() {
      let confirmed = confirm(this.allGeometriesWithParentsMessage)
      if (!confirmed) { return }
      this.$parent.close()

      const sectionId = this.item.parentId
      const target = this.item.type == 'perimeter-segments' ? constants.targets.PERIMETER : constants.targets.INTERIOR_PERIMETER
      const deleteFunction = Api.PerimeterSegments.destroyAll
      const postDeleteCallback = this.refreshView
      await this.geometryStore.deleteWithLocking({ sectionId, target, deleteFunction, postDeleteCallback })
    }
  },
  template: `
    <li v-if="isGeometryNode" class="legend-tooltip !cursor-auto">
      <a href='#' @click="deleteItem" :class="classes(canDeleteItem)">Delete</a>
      <span v-if="(isPerimeterNode || isInteriorPerimeterNode) && !canClosePolygon" class="tooltiptext !left-10">
        Unable to delete. Programmatic re-closing is currently unsupported due to configuration of remaining segment(s)
      </span>
    </li>
    <li v-if="isInventoryOrDefectNode">
      <a href='#' @click="deleteGeometry" :class="classes(canDeleteGeometry)">Delete Geometry</a>
    </li>
    <li v-if="isInteriorPerimeterGroupNode">
      <a href='#' @click.prevent="deleteGroupItems()" :class="classes(canDeleteGroupItems)">Delete All</a>
    </li>
    <li v-if="isInteriorPerimeterGroupNode">
      <a href='#' @click.prevent="deleteGroupGeometries()" :class="classes(canDeleteGroupGeometries)">Delete Geometries</a>
    </li>
    <li v-if="isTopLevelPerimeterNode">
      <a href='#' @click.prevent="deleteAllItems()" :class="classes(canDeleteAllItems)">Delete All</a>
    </li>
    <li v-if="isTopLevelPerimeterNode">
      <a href='#' @click.prevent="deleteAllGeometries()" :class="classes(canDeleteAllGeometries)">Delete Geometries</a>
    </li>
  `
}
