import { loadIconsIntoMap } from '../../../../icon_loader'
import colors from '../../../../colors'
import MapPin from '../../../../../assets/images/icons/custom/map-pin-stroked.svg'

const svgSetColor = (svg, color) => svg.replace(/fill="[^"]*"/, `fill="${color}"`)

const svgs = [
  {
    id: 'map-pin-blue',
    data: svgSetColor(MapPin, colors.neon.BLUE)
  },
  {
    id: 'map-pin-green',
    data: svgSetColor(MapPin, colors.score.GREEN)
  },
  {
    id: 'map-pin-yellow',
    data: svgSetColor(MapPin, colors.score.YELLOW)
  },
  {
    id: 'map-pin-red',
    data: svgSetColor(MapPin, colors.score.RED)
  }
]

const Icons = {}

Icons.load = function() {
  return loadIconsIntoMap(this.map, svgs)
}

export default Icons
