import { shallowRef } from 'vue'
import { mapStores } from 'pinia'
import { useGeometryStore, useMapStore, useModeStore } from '../../../../stores'
import SplitPerimeterSegmentForm from './split_perimeter_segment_form'

export default {
  name: 'SplitPerimeterSegmentLink',
  props: {
    item: Object
  },
  computed: {
    ...mapStores(useMapStore, useModeStore, useGeometryStore),
    hasGeometry() { return this.geometryStore.perimeterSegments[this.item.id] !== undefined },
    isGeometryNode() { return this.item.type === 'geometry' },
    isPerimeterNode() { return this.isGeometryNode && ['interiorPerimeter', 'perimeter'].includes(this.item.geometryType) },
    canSplitPerimeter() {
      if (!this.modeStore.inViewMode) { return false }
      return this.isPerimeterNode && this.hasGeometry
    }
  },
  methods: {
    openSplitPerimeterForm() {
      this.mapStore.$patch({
        popupData: null, // Close popup window in case it was open
        leftPanelSubMenuComponent: null, // Close submenu
        leftPanelSubMenuProps: null,
        showModal: true,
        modalComponent: shallowRef(SplitPerimeterSegmentForm),
        modalComponentProps: {
          geometryId: this.item.id
        }
      })
    }
  },
  template: `
    <li v-if="isPerimeterNode">
      <a
        href='#'
        :class="{
          'pointer-events-none text-gray-200': !canSplitPerimeter,
          'hover:underline': canSplitPerimeter
        }"
        @click="openSplitPerimeterForm">Split
      </a>
    </li>
  `
}
