import * as Turf from '@turf/turf'
import ZLayers from '../../../../z_layers'
import Popup from '../../../../images_popup'

const Properties = {}

const id = 'properties'

Properties.load = function() {
  const badgeColorToIconImage = (badgeColor) => {
    if (badgeColor == 'badge-green') return 'map-pin-green'
    if (badgeColor == 'badge-orange') return 'map-pin-yellow'
    if (badgeColor == 'badge-red') return 'map-pin-red'
    return 'map-pin-blue'
  }

  const points = this.properties.map((property) => {
    const point = Turf.point([property.long, property.lat])
    point.id = property.id
    point.properties.popup_text = `
      <div><a href="${property.path}" class="link font-bold">${property.name}</a></div>
      <div class="font-normal text-xs mt-1 text-gray-500">${property.address}</div>
      <div class="font-normal text-xs"><b>Last Inspection:</b> ${property.last_inspection}</div>
    `
    point.properties.iconImage = badgeColorToIconImage(property.color)
    return point
  })

  this.map.addSource(id, {
    'type': 'geojson',
    'data': Turf.featureCollection(points)
  })

  this.map.addLayer({
    'id': id,
    'type': 'symbol',
    'source': id,
    'layout': {
      'icon-image': ['get', 'iconImage'],
      'icon-allow-overlap': true,
      'visibility': 'visible'
    }
  }, ZLayers.myPosition.call(this, id))

  Popup.init.call(this, id, { noImagesMessage: null })
}

export default Properties
