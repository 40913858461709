import _ from 'lodash'
import { mapStores, mapState, mapActions } from 'pinia'

import { useMapStore, useGeometryStore, useModeStore, useLegendStore, useLockStore, tryWithError } from './stores'
import { Loader, ViewMode, EditMode, BulkMoveMode, PlacementMode, LeftPanel, RightPanel, Modal, Popup } from './components'
import {
  Grid,
  ClassificationIcons,
  Legend,
  ContextualToolbar,
  SectionLabels,
  PerimeterSegmentLabels,
  PerimeterSegmentAngles,
  ConditionScoreBackgrounds,
  DefectAngles,
  FeatureSelection
} from './plugins'

import ZLayers from '../../../z_layers'
import template from './app.html'

export default class {
  constructor(provider, mapboxToken, azureKey, eagleviewKey, location, survey, urls, readOnly) {
    this.provider = provider
    this.mapboxToken = mapboxToken
    this.azureKey = azureKey
    this.eagleviewKey = eagleviewKey
    this.location = location
    this.survey = survey
    this.urls = urls
    this.readOnly = readOnly
  }

  vue() {
    const args = {
      provider: this.provider,
      mapboxToken: this.mapboxToken,
      azureKey: this.azureKey,
      eagleviewKey: this.eagleviewKey,
      location: this.location,
      survey: this.survey,
      urls: this.urls,
      readOnly: this.readOnly
    }

    return {
      data() {
        return {
          map: null,
          draw: null,
          menu: null,
          plugins: null
        }
      },
      computed: {
        ...mapStores(useMapStore, useGeometryStore, useLegendStore, useModeStore, useLockStore),
        ...mapState(useMapStore, ['showModal', 'showPopup']),
        ...mapState(useModeStore, ['showLoader', 'showLeftPanel', 'inViewMode', 'inEditMode', 'inBulkMoveMode', 'inReadOnlyMode', 'inPlacementMode']),
      },
      methods: {
        ...mapActions(useGeometryStore, ['refreshFeatures']),
        ...mapActions(useLockStore, ['fetchAuthToken']),
        ...mapActions(useModeStore, ['initializeError']),
        modeChanged() {
          _.each(_.values(this.plugins), async (plugin) => { await plugin.modeChanged() })
        }

      },
      mounted() {
        this.modeStore.startLoading()
        if (args.readOnly) { this.modeStore.$patch({ initial: 'readOnly' }) }
        this.plugins = {
          grid: new Grid(),
          classificationIcons: new ClassificationIcons(),
          legend: new Legend(),
          contextualToolbar: new ContextualToolbar(),
          sectionLabels: new SectionLabels(),
          perimeterSegmentLabels: new PerimeterSegmentLabels(),
          perimeterSegmentAngles: new PerimeterSegmentAngles(),
          conditionScoreBackgrounds: new ConditionScoreBackgrounds(),
          defectAngles: new DefectAngles(),
          featureSelection: new FeatureSelection()
        }

        this.mapStore.$patch({ plugins: this.plugins })
        this.legendStore.$patch({ urls: args.urls })
        this.mapStore.initMap(args)
        this.map = this.mapStore.map
        this.draw = this.mapStore.draw
        this.menu = this.mapStore.menu

        this.map.on('load', async () => {
          await ZLayers.load.call(this.mapStore)

          if (args.provider != 'mapbox') { this.mapStore.switchMapProvider(args) }

          const response = await tryWithError(this.fetchAuthToken, [], this.initializeError)
          if (!response) { return false }

          const refresh = await this.refreshFeatures(this.initializeError)
          if (!refresh) { return }

          if (this.mapStore.isDeepLinkingToFeature) {
            const centered = this.mapStore.centerOnFeature()
            if (!centered) { this.initializeError(); return }

            this.legendStore.selectOnlyDeepLinkedFeature()
          }

          const fit = this.mapStore.fitMapToGeometries()
          if (!fit) { this.initializeError(); return }

          await Promise.all(_.values(this.plugins).map(async (plugin) => await plugin.initialize()))

          this.mapStore.repositionDrawLayers()
          this.mapStore.repositionPOILayers()
          this.modeStore.enterMode(this.modeStore.initial)
        })

        this.map.on('contextmenu', this.mapStore.rightClickCallback)
      },
      components: {
        Loader,
        Modal,
        LeftPanel,
        RightPanel,
        ViewMode,
        EditMode,
        Popup,
        BulkMoveMode,
        PlacementMode
      },
      template: template
    }
  }
}
