import * as Turf from '@turf/turf'
import { watch } from 'vue'
import _ from 'lodash'
import { useMapStore, useGeometryStore, useModeStore, useLegendStore } from '../stores'
import ZLayers from '../../../../z_layers'
import Vector from '../vector'
import colors from '../../../../colors'
import { constants } from '../constants'
import { isPoint, isLineString, isPolygon } from '../../../../geojson_utils'

export default class FeatureSelection {
  constructor() {
    this.sourceId = 'view-mode-selected-geometry'
    this.mapStore = useMapStore()
    this.geometryStore = useGeometryStore()
    this.modeStore = useModeStore()
    this.legendStore = useLegendStore()
    this.currentSelection = null
  }

  initialize() {
    this.mapStore.map.addSource(this.sourceId, {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] }
    })

    this.mapStore.map.addLayer({
      'id': this.sourceId + '-line',
      'type': 'line',
      'source': this.sourceId,
      'layout': {
        'visibility': 'visible',
        'line-cap': 'round',
        'line-join': 'round'
      },
      'filter': ['in', '$type', 'LineString', 'Polygon'],
      'paint': {
        'line-color': ['get', 'color'],
        'line-width': ['get', 'line_width'],
        'line-dasharray': [0.2, 2]
      }
    }, ZLayers.myPosition.call(this.mapStore, this.sourceId + '-bottom'))

    this.mapStore.map.addLayer({
      'id': this.sourceId + '-line-vertex',
      'type': 'circle',
      'source': this.sourceId,
      'layout': {
        'visibility': 'visible'
      },
      'filter': [
        'all',
        ['==', 'is_vertex', true],
        ['==', '$type', 'Point']
      ],
      'paint': {
        'circle-radius': ['get', 'vertex_radius'],
        'circle-color': ['get', 'color']
      }
    }, ZLayers.myPosition.call(this.mapStore, this.sourceId + '-top'))

    this.mapStore.map.addLayer({
      'id': this.sourceId + '-line-vertex-stroke',
      'type': 'circle',
      'source': this.sourceId,
      'layout': {
        'visibility': 'visible'
      },
      'filter': [
        'all',
        ['==', 'is_vertex', true],
        ['==', '$type', 'Point']
      ],
      'paint': {
        'circle-radius': ['+', ['get', 'vertex_radius'], 2],
        'circle-color': colors.WHITE
      }
    }, ZLayers.myPosition.call(this.mapStore, this.sourceId + '-middle'))

    this.mapStore.map.addLayer({
      'id': this.sourceId + '-circle',
      'type': 'circle',
      'source': this.sourceId,
      'layout': {
        'visibility': 'visible'
      },
      'filter': [
        'all',
        ['==', 'is_vertex', false],
        ['==', '$type', 'Point']
      ],
      'paint': {
        'circle-radius': ['*', ['get', 'point_radius'], 1.66],
        'circle-color': ['get', 'color']
      }
    }, ZLayers.myPosition.call(this.mapStore, this.sourceId + '-middle'))

    this.mapStore.map.addLayer({
      'id': this.sourceId + '-circle-stroke',
      'type': 'circle',
      'source': this.sourceId,
      'layout': {
        'visibility': 'visible'
      },
      'filter': [
        'all',
        ['==', 'is_vertex', false],
        ['==', '$type', 'Point']
      ],
      'paint': {
        'circle-radius': ['+', ['*', ['get', 'point_radius'], 1.66], 2],
        'circle-color': colors.WHITE
      }
    }, ZLayers.myPosition.call(this.mapStore, this.sourceId + '-bottom'))

    this.mapStore.map.on(constants.events.selectionChange, this.selectFeature)
    this.mapStore.map.on(constants.events.styleChange, this.styleChange)
    this.subscribeToLegendStore()
  }

  modeChanged() {
    if (this.modeStore.inViewMode || this.modeStore.inReadOnlyMode) {
      this.mapStore.map.on(constants.events.selectionChange, this.selectFeature)
      this.mapStore.map.on(constants.events.styleChange, this.styleChange)
    } else {
      this.mapStore.map.off(constants.events.selectionChange, this.selectFeature)
      this.mapStore.map.off(constants.events.styleChange, this.styleChange)
      this.clear()
    }
  }

  styleChange = (event) => {
    if (!this.currentSelection) { return }

    const currentSelectionlayerName = _.camelCase(this.currentSelection.sourceId)
    if (currentSelectionlayerName !== event.layerName) { return }

    const selectedFeatureAndVertices = this.mapStore.map.getSource(this.sourceId)._data.features
    selectedFeatureAndVertices.forEach(f => {
      if (event.property === 'color') { f.properties.color = event.value }
      if (event.property === 'lineWidth') { f.properties.line_width = event.value }
      if (event.property === 'pointRadius') { f.properties.point_radius = event.value }
      if (event.property === 'vertexRadius') { f.properties.vertex_radius = event.value }
    })

    this.mapStore.setViewSource(this.sourceId, Turf.featureCollection(selectedFeatureAndVertices))
  }

  deselectCurrent = () => {
    if (!this.currentSelection) { return }

    this.mapStore.setViewSource(this.sourceId, Turf.featureCollection([]))

    const originalSource = this.mapStore.map.getSource(this.currentSelection.sourceId)
    const unselectedGeometries = originalSource._data.features
    unselectedGeometries.push(this.currentSelection.feature)

    this.mapStore.setViewSource(this.currentSelection.sourceId, Turf.featureCollection(unselectedGeometries))
    this.currentSelection = null
  }

  selectFeature = async (event) => {
    if (!this.currentSelection && !event.feature) { return }
    if (this.currentSelection && !event.feature) { return this.deselectCurrent() }
    if (this.currentSelection && (event.feature.id === this.currentSelection.feature.id)) { return }
    if (this.currentSelection && (event.feature.id !== this.currentSelection.feature.id)) { this.deselectCurrent() }

    const target = this.removeTargetFromMap(event.feature.id, event.feature.source)
    this.addTargetWithSelectedStylesToMap(target, event.feature.source)

    this.currentSelection = { feature: target, sourceId: event.feature.source }
  }

  removeTargetFromMap = (targetFeatureId, sourceTargetId) => {
    const originalSource = this.mapStore.map.getSource(sourceTargetId)
    const originalFeatures = originalSource._data.features
    const [targetArray, remaining] = _.partition(originalFeatures, (g) => g.id === targetFeatureId)
    const target = targetArray[0] // There should only be one target feature
    this.mapStore.setViewSource(sourceTargetId, Turf.featureCollection(remaining))

    return target
  }

  addTargetWithSelectedStylesToMap = (target, sourceTargetId) => {
    const clone = Turf.clone(target) // Since the feature will be modified, prefer to work with a clone
    const layerName = _.camelCase(sourceTargetId) // Get the internal name we use to identify the layer
    const color = this.mapStore.colors.line[this.mapStore.layers[layerName].color]
    const lineWidth = this.mapStore.layers[layerName].lineWidth
    const pointRadius = this.mapStore.layers[layerName].pointRadius
    const vertexRadius = this.mapStore.layers[layerName].vertexRadius

    const selected = Turf.featureCollection([clone])
    if (isLineString(target) || isPolygon(target)) {
      // Ensure internal vertices are shown by using explode with lineStrings and polygons
      const vertices = Turf.explode(clone)
      vertices.features.forEach(f => f.properties.is_vertex = true)
      selected.features.push(...vertices.features)
    } else {
      selected.features[0].properties.is_vertex = false
    }

    selected.features.forEach(f => {
      f.properties.color = color
      f.properties.line_width = lineWidth
      f.properties.vertex_radius = vertexRadius
      f.properties.point_radius = pointRadius
    })
    this.mapStore.setViewSource(this.sourceId, selected)
  }

  subscribeToLegendStore() {
    watch(
      () => this.legendStore.noVisibilityIds,
      (newNoVisibilityIds) => {
        if (this.currentSelection && (this.modeStore.inViewMode || this.modeStore.inReadOnlyMode)) {
          if (newNoVisibilityIds.includes(this.currentSelection.feature.id)) {
            this.clear()
          } else {
            // After toggling on/off features from the legend, the original source is refresehd,
            // so we need to remove the selected feature.
            // No need to re-add selected feature because it's already in the map
            this.removeTargetFromMap(this.currentSelection.feature.id, this.currentSelection.sourceId)
          }
        }
      }
    )
  }

  clear() {
    this.mapStore.setViewSource(this.sourceId, Turf.featureCollection([]))
    this.currentSelection = null
    this.geometryStore.setSelectedFeature(null)
  }
}
