import * as Vue from 'vue/dist/vue.esm-bundler.js'
import _ from 'lodash'
import { useGeometryStore, useMapStore, useLegendStore, useModeStore } from '../../../../stores'
import { mapState, mapStores } from 'pinia'
import ChangeSegmentTypeModal from '../change_segment_type_modal'
import AddPointModal from '../add_point_modal'

import template from './index.html'

export default {
  template: template,
  computed: {
    ...mapState(useGeometryStore, ['selectedFeature', 'selectedCoordPaths']),
    ...mapState(useLegendStore, ['urls']),
    ...mapStores(useGeometryStore, useMapStore, useModeStore),
    showMenuOptions() { return this.modeStore.inEditMode && !this.isPoint },
    selectedFeatureType() {
      return _.startCase(this.geometryStore.typeOf(this.selectedFeature.id))
    },
    isPerimeterSegment() {
      return ['Perimeter', 'Interior Perimeter'].includes(this.selectedFeatureType)
    },
    isPoint() {
      return this.selectedFeature.geometry.type == 'Point'
    },
    isLineString(){
      return this.selectedFeature.geometry.type == 'LineString'
    },
    canAddPoint() {
      const geometryStore = useGeometryStore()
      return !this.isPoint && (geometryStore.selectedCoordPaths.length == 1)
    },
    selectedFeatureUrl() {
      const stringToReplace = encodeURIComponent('{feature_id}')
      const recordId = this.selectedFeature.properties.id
      return this.genericUrl.replace(stringToReplace, recordId)
    },
    genericUrl() {
      const type = this.selectedFeatureType
      if (type === 'Defect') { return this.urls.defect_path }
      if (type === 'Inventory') { return this.urls.inventory_path }
      if (type === 'Perimeter' || type === 'Interior Perimeter') { return this.urls. perimeter_segment_path }
    }
  },
  methods: {
    handleChangeType() {
      if (this.geometryStore.hasUnsavedChanges) {
        alert('There are unsaved changes to the map. Please save your changes before continuing.')
        return
      }

      this.mapStore.$patch({
        popupData: null, // Close popup window in case it was open
        showModal: true,
        modalComponent: Vue.shallowRef(ChangeSegmentTypeModal)
      })
    },
    handleAddPoint() {
      if (this.isLineString) {
        const coordinates = this.selectedFeature.geometry.coordinates
        const selectedCoordPath = this.selectedCoordPaths[0]
        const isFirstVertexSelected = selectedCoordPath == 0
        const isLastVertexSelected = selectedCoordPath == coordinates.length - 1

        if (isFirstVertexSelected) {
          this.geometryStore.createCoordinateBetween(this.selectedFeature, 0, 1)
          this.geometryStore.programmaticSelectFeature(this.selectedFeature.id, '0')
          return
        }

        if (isLastVertexSelected) {
          this.geometryStore.createCoordinateBetween(this.selectedFeature, coordinates.length - 2, coordinates.length - 1)
          this.geometryStore.programmaticSelectFeature(this.selectedFeature.id, (coordinates.length - 1).toString())
          return
        }
      }

      this.mapStore.$patch({
        popupData: null, // Close popup window in case it was open
        showModal: true,
        modalComponent: Vue.shallowRef(AddPointModal)
      })
    }
  }
}
