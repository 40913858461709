import _ from 'lodash'
import * as Turf from '@turf/turf'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

import { constants } from '../../constants'
import { Api } from '../../api'
import { useMapStore, useModeStore, useLockStore, tryWithError } from '../'
import { cleanFeatureProperties, featuresWithQuantities, closePolygon, getPolygonConnectionStatus } from './utils'
import { preprocessRecords, perimeterCenter, namedColorToColorCode } from './utils'
import Section from './section'

const programmaticSelectFeature = function (featureId, selectedCoordPath = null) {
  const mapStore = useMapStore()
  const feature = mapStore.draw.get(featureId)
  const isPoint = feature.geometry.type == MapboxDraw.constants.geojsonTypes.POINT

  // Mapbox draw doesn't execute the changeMode if the feature was already selected,
  // for this reason we make sure that there is no previously selected feature
  mapStore.draw.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT)
  this.selectedCoordPaths = null

  if (isPoint) {
    mapStore.draw.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, { featureIds: [feature.id] })
  } else {
    mapStore.draw.changeMode(MapboxDraw.constants.modes.DIRECT_SELECT, { featureId: feature.id, coordPath: selectedCoordPath })
  }
  this.setSelectedFeature(feature)
}

const setSections = function() {
  const elems = [..._.values(this.defects), ..._.values(this.perimeterSegments), ..._.values(this.inventories)]
  const elemsBySection = _.groupBy(elems, 'section_id')

  this.sections = _.mapValues(elemsBySection, (elems) => {
    return new Section(elems)
  })
}

const refreshFeatures = async function(featureError) {
  const dataIdFromGeometry = (geos) => { _.forEach(geos, (g) => { if (g.data) { g.data.id = g.id } }) }
  const results = await Promise.all(this.types.map(t => Api.Geometries.geometryByType(t)))

  if (results.some(result => result.errored())) {
    featureError()
    return false
  }

  const geometries = results.map((result) => result.data)
  this.types.forEach((t, i) => {
    const typeName = _.camelCase(t)
    this[typeName] = preprocessRecords(geometries[i])
  })

  this.setSections()

  // Assign an integer to each classification_id for use in the iconography
  let counter = 1
  _.forEach({...this.defects, ...this.inventories}, (value) => {
    const classificationId = value.classification_id;
    if (!this.classificationIdToNumber[classificationId]) {
      this.classificationIdToNumber[classificationId] = counter++
    }
  })

  return true
}

const saveAll = async function() {
  if (!this.hasUnsavedChanges) { return true }

  const modeStore = useModeStore()
  const features = _.values(this.changedFeatures)
  const cleanedFeatures = cleanFeatureProperties(features)
  const saveArgs = [featuresWithQuantities(cleanedFeatures)]
  const response = await tryWithError(Api.Geometries.saveAllFeatures, saveArgs, modeStore.savingError)
  if (!response) { return false }

  this.clearChanges()
  return true
}

const deleteWithLocking = async function({ deleteFunction, postDeleteCallback, ...args }) {
  const modeStore = useModeStore()
  const lockStore = useLockStore()

  modeStore.startLoading()
  let result = await lockStore.confirmClaim()
  if (!result) {
    if (result !== null) { modeStore.stopLoading() } // null represents a fetch error
    return false
  }

  result = (await lockStore.tryLock(lockStore.claimLock, modeStore.deletingFeatureError))
    && (await tryWithError(deleteFunction, args, modeStore.deletingFeatureError))
    && (await lockStore.tryLock(lockStore.releaseLock, modeStore.deletingFeatureError))
    && (await postDeleteCallback())

  if (!result) { return false } // One of the above steps returned false

  modeStore.stopLoading()
  return true
}

const moveAndRotateFeatures = function(features, { vector = null, angle = null, pivot = null, asCollection = false }) {
  const decorateFeatures = (feats) => asCollection ? Turf.featureCollection(feats) : feats

  if (vector === null && (angle === null || pivot === null)) return decorateFeatures(features)

  let movedFeatures = features

  if (vector) {
    const [from, to] = vector.geometry.coordinates
    const distance = Turf.distance(from, to)
    const bearing = Turf.bearing(from, to)
    movedFeatures = movedFeatures.map((f) => Turf.transformTranslate(f, distance, bearing))
  }

  if (angle && pivot) {
    movedFeatures = movedFeatures.map((f) => Turf.transformRotate(f, angle, { pivot: pivot.geometry.coordinates }))
  }


  return decorateFeatures(movedFeatures)
}

const moveAndRotateAllFeatures = function({ sectionId = null, vector = null, angle = null, pivot = null }) {
  if (vector === null && (angle === null || pivot === null)) return false
  if (_.size(this.changedFeatures) > 0) return false

  _.map(this.types, _.camelCase).forEach((t) => {
    const geos = sectionId === null ? this[t] : _.filter(this[t], ['section_id', sectionId])
    const features = _.chain(geos).map('data').compact().value()
    const moved = this.moveAndRotateFeatures(features, { vector, angle, pivot })
    this.changedFeatures = { ...this.changedFeatures, ..._.keyBy(moved, 'id') }
  })
  return true
}

const setSelectedFeature = function (f) {
  const modeStore = useModeStore()
  const mapStore = useMapStore()

  this.selectedFeature = f || null
  if (modeStore.inViewMode || modeStore.inReadOnlyMode) {
    mapStore.map.fire(constants.events.selectionChange, { feature: this.selectedFeature })
  }
  if (!this.selectedFeature) { this.setSelectedCoordPaths([]) }
}

const setSelectedCoordPaths = function(coordPaths) {
  this.selectedCoordPaths = []
  this.selectedCoordPaths = coordPaths
}

const createCoordinateBetween = function(feature, coordPathA, coordPathB) {
  const mapStore = useMapStore()
  const isPolygon = feature.geometry.type == MapboxDraw.constants.geojsonTypes.POLYGON

  if (isPolygon) {
    const [ringPath, coordPathInRingA] = coordPathA.split('.').map(x => parseInt(x, 10))
    const [_, coordPathInRingB] = coordPathB.split('.').map(x => parseInt(x, 10))
    const coordA = feature.geometry.coordinates[ringPath][coordPathInRingA]
    const coordB = feature.geometry.coordinates[ringPath][coordPathInRingB]
    const midpoint = Turf.midpoint(Turf.point(coordA), Turf.point(coordB))

    const newCoordPath = coordPathInRingA > coordPathInRingB ? coordPathInRingA : coordPathInRingB
    feature.geometry.coordinates[ringPath].splice(newCoordPath, 0, midpoint.geometry.coordinates)
  } else {
    const coordA = feature.geometry.coordinates[coordPathA]
    const coordB = feature.geometry.coordinates[coordPathB]
    const midpoint = Turf.midpoint(Turf.point(coordA), Turf.point(coordB))

    feature.geometry.coordinates.splice(coordPathB, 0, midpoint.geometry.coordinates)
  }
  mapStore.draw.add(this.selectedFeature)
  if ([constants.targets.PERIMETER, constants.targets.INTERIOR_PERIMETER].includes(this.typeOf(feature.id))) {
    mapStore.plugins.perimeterSegmentLabels.recalculate(this.selectedFeature)
  }
  if (this.typeOf(feature.id) == constants.targets.PERIMETER) {
    mapStore.plugins.perimeterSegmentAngles.recalculate(this.selectedFeature)
  }
  if (this.typeOf(feature.id) == constants.targets.DEFECT) {
    mapStore.plugins.defectAngles.recalculate(this.selectedFeature)
  }

  this.pushFeature(this.selectedFeature)
}

const closeAndSavePolygon = async function(lineStrings) {
  const updatedLineStrings = closePolygon(lineStrings)
  updatedLineStrings.forEach((ls) => this.pushFeature(ls))
  const saved = await this.saveAll()
  if (!saved) { this.clearChanges() }
  return saved
}

const setSectionCenterVisibility = function(sectionId, visible) {
  if (!this.sections[sectionId].center) { return }

  this.sections[sectionId].centerVisibility = visible
}

export {
  programmaticSelectFeature,
  setSections,
  refreshFeatures,
  saveAll,
  deleteWithLocking,
  moveAndRotateFeatures,
  moveAndRotateAllFeatures,
  setSelectedFeature,
  setSelectedCoordPaths,
  createCoordinateBetween,
  closeAndSavePolygon,
  setSectionCenterVisibility
}
